*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.box{
    width: 100%;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.form{
    max-width:400px;
    min-width: 340px;
    max-height:auto;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
}

.form-title{
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 1px;
}
.error{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px 0px 20px 0;
    font-size:15px;
    font-weight: 700;
    color:red ;
}
.form-group{
    height: 58px;
    margin-bottom: 20px;
    position: relative;
}
.form-control{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: none;
    border: 1px solid #1F1F1F;
    outline: none;
    padding: 15px;
    border-radius: 3px;
    z-index: 1;
}
.form-label{
    position: absolute;
    left: 19px;
    top: 20px;
    color: #1B1C30;
    background-color: white;
    padding: 0px 6px;
    font-size: 14px;
    transition: 0.3s;
    font-size: 20px;
    font-weight: 700;
}

.form-control:focus+ .form-label{
    top: -10px;
    z-index: 10;
    color: #1F1F1F;
}
.form-control:focus{
    border-color: #1F1F1F;
}
.form-control:not(:focus):valid+label{
    top: -10px;
    z-index: 10;
}
input[type="checkbox"]{
    width: 20px;
    height: 18px;
    margin:0px 10px;
    font-size: 20px;
    border: none;
    outline: none;
}
.showlabel{
    font-size:20px;
    font-weight: 700;
}

.bottom-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0px 10px 10px 0px;
}
.bottom-box>a:first-child{
    color: #125feb;
    text-decoration: none;
    font-size:20px;
    font-weight: 700;
}
.bottom-box a:last-child{
    background-color: #1F1F1F;
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
    color: #ffffff;
}
.form-button{
    display: block;
    padding: 10px 20px;
    outline: none;
    border: none;
    background-color: #1F1F1F;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
}
.form-button:hover{
    box-shadow: 0 10px  36px rgba(0,0,0,0.15);
}

::selection{
    background-color: #125feb;
}
.form-sign{
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn{
 width: 200px;
 height:50px;
 border-radius: 4px;
 border: none;
 outline: none;
 background:transparent;
 cursor: pointer;
 color:black;
 position: relative;
 font-weight: 700;
}

.layer{
    background-color:#1F1F1F;
    height: 100%;
    width:0%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px;
    z-index: -1;
    transition: 0.5s;
}
.btn:hover .layer{
width: 100%;
transform: skew(0deg);
color: white;
}
.btn:hover{
    color: white;
}
.register-btn{
    display: flex;
    justify-content: center;
    width: 100%;
    color: #ffffff;
    height: auto;
    margin:auto;
    justify-content: space-evenly;
}
.register-btn button{
    border: none;
    outline: none;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 22px;
    background-color: #1B1C30;
    color: #ffffff;
    font-weight: 700;
    font-family: 'Josefin Slab', serif;
    letter-spacing: 1px;
}

.paymentmain{
    display: flex;
    padding: 10px;
    margin: 10px;
    flex-direction:row;
    position: relative;
}
