@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Josefin+Slab:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}
html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Josefin Slab', serif;
}

#root {
  width: 100%;
  height: 100%;
}

/* 
a{
  text-decoration: none;
}
ul{
  list-style: none;
}
button{
  cursor:pointer;
} */
