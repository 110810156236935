.spinner{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.loadspinner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.loader1{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 6px solid;
    border-color: rgb(1, 7, 90) transparent rgb(4, 59, 161) transparent;
    animation: loader1 5s linear infinite;
}
.loader2{
    margin:4px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 5px solid ;
    border-color: #00b1dd transparent #17DDCE transparent;
    animation: loader2 3s linear infinite;
}
.loader3{
    margin:9px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid;
    border-color:blueviolet transparent rgb(174, 37, 187) transparent;
    animation: loader2 2s linear infinite;
    
}

@keyframes loader1{
    /* 0%{
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(90deg);
    }
    75%{
        transform: rotate(180deg);
    }
    100%{
        transform: rotate(270deg);
    } */
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
    
}

@keyframes loader2{
    /* 0%{
        transform: rotate(90deg);
    }
    50%{
        transform: rotate(180deg);
    }
    75%{
        transform: rotate(180deg);
    }
    100%{
        transform: rotate(270deg);
    } */
    from{
        transform: rotate(360deg);
    }
    to{
        transform: rotate(0deg);
    }
    
}

@keyframes loader3{
    /* 0%{
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(90deg);
    }
    75%{
        transform: rotate(180deg);
    }
    100%{
        transform: rotate(360deg);
    } */
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
    
}