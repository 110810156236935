:root{
    --BlueColor:#20B8F3;
    --VoiletColor:#5631FA;
    --YellowColor:#FBA31F;
    --orangeColor:#FC6B21;
    --NavyColor:#1B1C30;
  }
.scrollMain{
    width: 100%;
    justify-content: center;
    align-items: center;
    padding:15px 0px 10px 15px;
}

.scrollContainer{
    width:100%;
    height:auto;
    display: flex;
    flex-direction: column;
     
}
.MensContainer{
    margin-top:1rem;
    width: 100%;
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    position: relative; 
}
.MensTitle{
    position:absolute;
    top: 5px;
    left:10px;
}
.mensProduct{
    width: 300px;
    height: auto;
    margin:50px 15px 0px 15px;
    box-shadow: 5px 5px 15px rgba(0,0, 0,0.5);
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    transition: all 1s;
}

.mensProduct:hover{
    transform: scale(1.03);
}
.mensProduct img{
    width:100%;
    margin-bottom: 10px;
}
.mensProduct p{
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
    width:50% ;
    height: auto;
    height: auto;
}
.mensProduct button{
    width:60%;
    height:40px;
    margin: 5px 0px 10px 0px;
    border-radius: 4px;
    border: none;
    outline: none;
    background:transparent;
    cursor: pointer;
    position: relative;
    font-weight:600;
    font-size:18px;
    color: white;
}
.layer{
    background-color:#1F1F1F;
    height: 100%;
    width:0%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px;
    z-index: -1;
    transition: 0.5s;

}
.mensProduct:hover .layer{
    width: 100%;
    color: white;
    }

@media (max-width:700px) {
    .mensProduct{
        width:200px;
        height:auto;
        margin:50px 10px 0px 5px;
    }
}
@media (max-width:400px) {
        .MensContainer{
            max-height:auto;
            min-width:350px;
            flex-wrap: nowrap;
            overflow-x:scroll;
            white-space: nowrap;
            overflow-y: hidden;
            margin-top:1rem;
            width: 100%;
            
        }
        /* .MensContainer::-webkit-scrollbar{
            width: 0;
        } */
         .mensProduct{
            width:110px;
            height:110px;
            margin:50px 10px 0px 5px;
            box-shadow:none;
        }
         .mensProduct img{
            width:100%;
            height:auto;
            
        }
         .mensProduct button{
            width:60%;
            height:auto;
            border-radius: 4px;
            border: none;
            outline: none;
            background:transparent;
            cursor: pointer;
            position: relative;
            font-weight:600;
            font-size:18px;
            color: white;
        }
        .MensTitle{
            position:absolute;
            top: 5px;
            left:5px;
            font-size: 10px;
        }
        .mensProduct p{
            font-size:12px;
            font-weight:800;
            letter-spacing: 1px;
        }
        .mensProduct:hover{
            transform:none;
        }
        .layer{
            width: 100%;
        }
    
    }
