.categoryContainer{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.categoryMain{
    width:100%;
    height:auto;
    min-width:350;
    display: flex;
    margin:1rem;
}
.category{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    position:relative;
    transition: all 1s;
}
.category:hover{
    transform: scale(1.01);
}
.category>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.content{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size:60px;
    letter-spacing:4px;
    font-weight: 800;
    text-decoration: solid;
    color:white;
    opacity: 1;
}
.category button{
    width: 200px;
    height: 50px;
    background-image:url(../Images/button.png);
    border: none;
    font-size:25px;
    font-weight:600;
    letter-spacing: 3px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 1s;
}
@media (max-width:850px) {
    .categoryMain{
        flex-wrap:wrap;
    }
}
