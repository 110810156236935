.oderContainer{
    width: 100%;
    height: 100Vh;
}
.order-Row{
    /* width: 100%; */
    min-height: 100vh;
    /* height: auto; */
    display: flex;
    /* border: 1px solid blue; */
}
.orderTitle{
    display: flex;
    justify-content: center;
    margin: 20px;
}
.order-col:first-child{
flex:3;
/* background-color: antiquewhite; */

}
.order-col:last-child{
    flex:2;
    /* background-color: aquamarine; */
   
}
.order-address{
    margin:10px 10px 10px 10px ;
    padding:10px;

}
.order-address>p{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 20px 0px 0px 20px;
}
.order-address>span{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 20px 0px 0px 20px;
}
.order-edit{

    margin:20px 0px 0px 30px; 
    display: flex;
    justify-content: center ;
}
.order-edit a{
    text-decoration: none;
}
.order-edit button{
    /* width:100px; */
    background-color: #1B1C30;
    border: none;
    outline: none;
    padding: 10px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s;
}
.order-edit button:hover{
    background-color: #ffffff;
    color: #1B1C30;
}
.order-display{
    margin:10px 0px 10px 10px ;
    padding:10px;
}
.order-display>h2{
    text-align: center;
}
.order-content{
    margin: 10px;
    padding:10px;
    width: 350px;
    box-shadow: 0px 0px 15px rgba(0,0, 0,0.5);
    display: flex;
    flex-direction: row;
    border-radius:10px;
}
.order-image{
    width:100px;
    height:100px;
    display: flex;
}
.order-image>img{
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100% ;
}
.order-text{
    margin: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    flex-direction:row;
}
.order-text p{
    margin: 0px 10px 0px 10px;
    font-size: 20px;
    font-weight: 700;
}
.order-cartEdit{
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 10px 0px 0px 0px;
}
.order-cartEdit button{
    background-color: #1B1C30;
    border: none;
    outline: none;
    padding: 10px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s;
}
.order-cartEdit button:hover{
     background-color: #ffffff;
    color: #1B1C30;
}
.order-body{
    width: 100%;
    text-align: center;
    padding:  20px 0px 10px 0px;
    /* box-shadow: 0px 0px 15px rgba(0,0, 0,0.5); */
    border-radius:10px;
    display: flex;
    justify-content: center;
}
.order-body >table{
    width:80%;
    border-collapse: collapse;
    margin: 1rem;
}
.order-body >table>caption{
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 2px;
    margin:10px;
}
.order-body  tr{
    font-size: 23px;
    table-layout: fixed;
    border:1px dashed #1B1C30;
}
.order-body  td{
    font-size: 20px;
    font-weight: 700;
    padding: 5px;
}
.order-place{
    width: 100%;
    height:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.order-place button{
    border:none;
    outline:none;
    padding: 10px;
    background-color: #1B1C30;
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 2px;
    border-radius: 10px;
    transition: all 0.5s;
    cursor: pointer;
}
.order-place button:hover{
     background-color: #ffffff;
    color: #1B1C30;
}
@media (max-width:800px){
    .order-Row{
        flex-direction: column;
    }
}
@media (max-width:400px){
    .order-content{
        width:100%;
       margin: 10px 0px 0px 0px;
    }
}